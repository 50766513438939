import 'antd/dist/antd.css';
import '../public/bootstrap/bootstrap.min.css';
import '../public/font-awesome/css/all.min.css';
import '../public/odometer/odometer-theme-default.css';
import '../stylesheets/main.scss';
import App from 'next/app';

import { createContext, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { ExitIntentForm } from '../components';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const NonceContext = createContext(undefined);
const RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

export default function MyApp({ Component, pageProps, nonce }) {
	const router = useRouter();
	const nonceRef = useRef(nonce);

	useEffect(() => {
		if (window.location.hash === '#pricing-plans') {
			router.replace('/pricing-plans');
		}
	}, []);

	useEffect(() => {
		if (nonce) {
			nonceRef.current = nonce;
		}
	}, [nonce]);

	return (
		<>
			<NonceContext.Provider value={nonceRef.current}>
				<GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
					<Component {...pageProps} />
					<ExitIntentForm />
				</GoogleReCaptchaProvider>
			</NonceContext.Provider>
		</>
	);
}

MyApp.getInitialProps = async (Context) => {
	const props = await App.getInitialProps(Context);
	const { ctx } = Context;
	const nonce = ctx.req?.headers?.['x-nonce'];

	return {
		...props,
		nonce,
	};
};
