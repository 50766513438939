import super800 from '../public/images/customer-logos/800super.jpg';
import aic from '../public/images/customer-logos/aic.png';
import bca from '../public/images/customer-logos/bca.png';
import cargill from '../public/images/customer-logos/cargill.jpg';
import dsta from '../public/images/customer-logos/dsta.png';
import edb from '../public/images/customer-logos/edb.png';
import foodpanda from '../public/images/customer-logos/foodPanda.jpg';
import homeAffairs from '../public/images/customer-logos/homeAffairs.png';
import hyakunousha from '../public/images/customer-logos/hyakunousha.jpg';
import ite from '../public/images/customer-logos/ite.png';
import johorPort from '../public/images/customer-logos/johorPort.png';
import jtc from '../public/images/customer-logos/jtc.png';
import mandai from '../public/images/customer-logos/mandai.jpg';
import ministryEducation from '../public/images/customer-logos/ministryEducation.jpg';
import mnd from '../public/images/customer-logos/mnd.jpg';
import nus from '../public/images/customer-logos/nus.jpg';
import pathLight from '../public/images/customer-logos/pathLight.png';
import providentFund from '../public/images/customer-logos/providentFund.png';
import republicPolytechnic from '../public/images/customer-logos/republicPolytechnic.png';
import rhb from '../public/images/customer-logos/rhb.jpg';
import sbs from '../public/images/customer-logos/sbs.png';
import sephora from '../public/images/customer-logos/sephora.png';
import shiseido from '../public/images/customer-logos/shiseido.png';
import singAmericanSchool from '../public/images/customer-logos/singAmericanSchool.png';
import smrt from '../public/images/customer-logos/smrt.jpg';
import smu from '../public/images/customer-logos/smu.jpg';
import suntec from '../public/images/customer-logos/suntec.png';
import temasek from '../public/images/customer-logos/temasek.png';
import unilever from '../public/images/customer-logos/unilever.jpg';
import ych from '../public/images/customer-logos/ych.jpg';

export const allCustomerLogos = [
	super800,
	aic,
	bca,
	cargill,
	dsta,
	edb,
	foodpanda,
	homeAffairs,
	unilever,
	hyakunousha,
	ite,
	johorPort,
	jtc,
	mandai,
	ministryEducation,
	mnd,
	nus,
	pathLight,
	providentFund,
	republicPolytechnic,
	rhb,
	sbs,
	sephora,
	shiseido,
	singAmericanSchool,
	smrt,
	smu,
	suntec,
	temasek,
	ych,
];

export {
	super800,
	aic,
	bca,
	cargill,
	dsta,
	edb,
	foodpanda,
	homeAffairs,
	hyakunousha,
	ite,
	johorPort,
	jtc,
	mandai,
	ministryEducation,
	mnd,
	nus,
	pathLight,
	providentFund,
	republicPolytechnic,
	rhb,
	sbs,
	sephora,
	shiseido,
	singAmericanSchool,
	smrt,
	smu,
	suntec,
	temasek,
	unilever,
	ych,
};
