import Header from '../Header';
import Footer from '../Footer';
import Meta from '../Meta';
import { pageSEO } from '../../data/SEO';

export default function Layout({ children, pageName, style }) {
	return (
		<div className="wrapper" style={style}>
			<Meta {...pageSEO(pageName)} />

			<Header />

			<main>{children}</main>

			<Footer />
		</div>
	);
}
