import { allCustomerLogos } from '../utils/customerLogos';
import { Carousel } from 'antd';
import { super800, aic, nus } from '../utils/customerLogos';
import InfiniteSlider from './InfiniteSlider';

const contentStyle = {
	background: '#364d79',
};

export default function Customers() {
	return (
		<section className="customers">
			<div className="customers__wrapper">
				<h3 className="customers__heading">Deployed in Properties of All Types</h3>
				<InfiniteSlider>
					{allCustomerLogos.map((logo, index) => (
						<img src={logo} alt={`customer_logo_${index}`} key={index} />
					))}
				</InfiniteSlider>
			</div>
		</section>
	);
}
