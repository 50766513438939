import { useEffect } from 'react';

function InfiniteSlider({ children }) {
	return (
		<section className="inf-slider-containter">
			<div className="logos-slide">
				{children.map((logo, index) => (
					<span key={index}>{logo}</span>
				))}
			</div>
			<div className="logos-slide repeat">
				{children.map((logo, index) => (
					<span key={index}>{logo}</span>
				))}
			</div>
		</section>
	);
}

export default InfiniteSlider;
